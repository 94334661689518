import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '',
    name: 'home',
    component: () => import('@/views/pageLayout.vue'),
    children: [
      { path: '', redirect: 'home'},
      { path: '/home', component: () => import('@/views/homePage.vue'), name: 'home' },
      { path: '/services', component: () => import('@/views/servicesPage.vue') },
      { path: '/clients', component: () => import('@/views/clientsPage.vue') },
      { path: '/aboutus', component: () => import('@/views/aboutUsPage.vue') },
      { path: '/fleet', component: () => import('@/views/fleetPage.vue'), name: 'fleet' },
      { path: '/enquiry', component: () => import('@/views/enquiryPage.vue'), name: 'enquiry' },
      { path: '/howwework', component: () => import('@/views/howWeWorkPage.vue'), name: 'howwework' }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
